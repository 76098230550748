<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <h1
              data-test="title-form"
              class="mb-6 tt-text-headline-1"
            >
              {{ edit ? 'Редактирование' : 'Добавление нового' }} уровня
            </h1>
            <VRow>
              <VCol cols="12">
                <TTTextField
                  v-model="entity.name"
                  large
                  label="Название"
                  placeholder="Введите название"
                  :error="validation('name').status || uniqueError.status"
                  :error-messages="validation('name').value || uniqueError.message"
                  data-test="input-name"
                  @input="v => handleChange('name',v)"
                />
              </VCol>
              <VCol cols="12">
                <VCheckbox
                  v-model="entity.for_tutor"
                  hide-details="auto"
                  single-line
                  color="tt-black"
                  class="mt-0 pt-0"
                  :ripple="false"
                >
                  <template #label>
                    <p class="tt-text-body-2 mb-0">
                      Уровень для трека наставничества
                    </p>
                  </template>
                </VCheckbox>
              </VCol>
            </VRow>
            <VDivider class="mt-3 mb-6" />
            <VRow no-gutters>
              <VCol>
                <h3 class="tt-text-subtitle">
                  Условие открытия этого уровня
                </h3>
              </VCol>
            </VRow>
            <VRow class="mb-6">
              <VCol cols="5">
                <TTSelect
                  v-model="condition"
                  large
                  hide-details
                  attach
                  :items="levelConditionTypes"
                  data-test="condition-level-type"
                />
              </VCol>
              <VCol
                v-if="condition !== ''"
                cols="7"
              >
                <VRow>
                  <VCol class="pt-3 pb-0">
                    <TTTextField
                      v-mask="'####'"
                      :value="timeFrameNumber"
                      large
                      placeholder="Количество"
                      hide-details="auto"
                      data-test="level-time-frame-number"
                      :error="validation(selectedTimeFrame).status"
                      :error-messages="validation(selectedTimeFrame).value"
                      @input="handleTimeChange('timeFrameNumber', $event, selectedTimeFrame)"
                    />
                  </VCol>
                  <VCol class="pt-3 pb-0">
                    <TTSelect
                      v-model="selectedTimeFrame"
                      large
                      :items="timeFrame"
                      hide-details
                      attach
                      data-test="level-time-frame"
                      @input="handleTimeChange(selectedTimeFrame, $event)"
                    />
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
            <VRow>
              <VCol class="pt-0">
                <h3 class="mb-6 tt-text-subtitle">
                  Процент прохождения этого уровня для открытия следующего
                </h3>
                <VRow>
                  <VCol cols="7">
                    <div class="d-flex align-center">
                      <span
                        data-test="text"
                      >{{ entity.open_percent }} %</span>
                      <VSlider
                        v-model="indexStep"
                        hide-details="auto"
                        color="tt-black"
                        track-color="tt-light-mono-24"
                        class="px-3"
                        step="1"
                        max="11"
                        data-test="range-open-percent"
                        ticks
                        :error="validation('open_percent').status"
                        :error-messages="validation('open_percent').value"
                      >
                        <template #message="{message}">
                          <span class="tt-text-body-2">{{ message }}</span>
                        </template>
                      </VSlider>
                      <span>100%</span>
                    </div>
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
            <VDivider class="mt-3 mb-6" />
            <VRow>
              <VCol
                cols="12"
                class="pb-3"
              >
                <h3 class="tt-text-subtitle">
                  Загрузите изображение
                </h3>
              </VCol>
              <VCol cols="6">
                <h4 class="mb-2 tt-text-body-2">
                  Активный уровень (Муж.)
                </h4>
                <div>
                  <UploadImage
                    compact
                    :value="entity.icon_male_url"
                    :error="validation('icon_male_url').status"
                    :error-messages="validation('icon_male_url').value"
                    @input="v => handleChange('icon_male_url',v)"
                  />
                </div>
              </VCol>
              <VCol cols="6">
                <h4 class="mb-2 tt-text-body-2">
                  Активный уровень (Жен.)
                </h4>
                <div>
                  <UploadImage
                    compact
                    :value="entity.icon_female_url"
                    :error="validation('icon_female_url').status"
                    :error-messages="validation('icon_female_url').value"
                    @input="v => handleChange('icon_female_url',v)"
                  />
                </div>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <h4 class="mb-2 tt-text-body-2">
                  Недоступный уровень (Муж.)
                </h4>
                <div>
                  <UploadImage
                    compact
                    :value="entity.icon_male_inactive_url"
                    :error="validation('icon_male_inactive_url').status"
                    :error-messages="validation('icon_male_inactive_url').value"
                    @input="v => handleChange('icon_male_inactive_url',v)"
                  />
                </div>
              </VCol>
              <VCol cols="6">
                <h4 class="mb-2 tt-text-body-2">
                  Недоступный уровень (Жен.)
                </h4>
                <div>
                  <UploadImage
                    compact
                    :value="entity.icon_female_inactive_url"
                    :error="validation('icon_female_inactive_url').status"
                    :error-messages="validation('icon_female_inactive_url').value"
                    @input="v => handleChange('icon_female_inactive_url',v)"
                  />
                </div>
              </VCol>
            </VRow>
            <VRow>
              <VCol
                align="left"
                class="mt-3"
              >
                <TTBtn
                  color="tt-secondary"
                  large
                  depressed
                  data-test="button-cancel"
                  @click="handleClose"
                >
                  Отменить
                </TTBtn>
                <TTBtn
                  :disabled="saving"
                  :loading="saving"
                  depressed
                  class="ml-6"
                  large
                  data-test="button-save"
                  @click="handleSave"
                >
                  {{ edit ? 'Сохранить' : 'Добавить' }}
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validation, handleError, watchValidationStatus } from '@/services';
import { cutToComma } from '@/utils';
import * as snamiApi from '@/services/backend/snami';
import UploadImage from '@/components/ui/Uploader.vue';
import { levelSteps } from '@/constants';

export default {
  name: 'LevelForm',
  components: { UploadImage },
  directives: { mask },
  props: {
    editEntity: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    currentLocation: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      validationStatus: [],
      uniqueError: {
        status: false,
        message: '',
      },
      entity: {
        name: '',
        open_percent: '70',
        icon_male_url: '',
        icon_male_inactive_url: '',
        icon_female_url: '',
        icon_female_inactive_url: '',
        condition: {
          start: {},
          probation: {},
        },
      },
      levelConditionTypes: [
        {
          value: '',
          text: 'После прохождения предыдущего уровня',
        },
        {
          value: 'beforeStart',
          text: 'До выхода на работу',
        },
        {
          value: 'afterStart',
          text: 'После выхода',
        },
        {
          value: 'beforeProbation',
          text: 'До окончания испытательного',
        },
        {
          value: 'afterProbation',
          text: 'После окончания испытательного',
        },
      ],
      condition: '',
      selectedTimeFrame: 'day',
      timeFrame: [
        // { text: 'Часов', value: 'hour' },
        { text: 'Дней', value: 'day' },
        { text: 'Недель', value: 'week' },
        { text: 'Месяцев', value: 'month' },
      ],
      timeFrameNumber: null,
      activeMaleFile: null,
      inactiveMaleFile: null,
      activeFemaleFile: null,
      inactiveFemaleFile: null,
      indexStep: 8,
      levelSteps,
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
    indexStep(newIndexStep) {
      this.handleChange('open_percent', this.levelSteps[newIndexStep]);
    },
  },
  created() {
    if (this.edit) {
      this.entity = { ...this.editEntity };
      if (this.editEntity.condition.start !== null) {
        const conditionStart = { ...this.editEntity.condition.start };
        const timeCondition = Object.entries(conditionStart).find(([, value]) => value !== 0);
        if (timeCondition === undefined) {
          this.timeFrameNumber = 0;
          this.selectedTimeFrame = 'day';
          this.condition = 'afterStart';
        } else {
          const [frame, number] = timeCondition;
          this.timeFrameNumber = number;
          this.selectedTimeFrame = frame;
          if (this.timeFrameNumber < 0) {
            this.condition = 'beforeStart';
          } else {
            this.condition = 'afterStart';
          }
        }
      } else if (this.editEntity.condition.probation !== null) {
        const conditionStart = { ...this.editEntity.condition.probation };
        const timeCondition = Object.entries(conditionStart).find(([, value]) => value !== 0);
        if (timeCondition === undefined) {
          this.timeFrameNumber = 0;
          this.selectedTimeFrame = 'day';
          this.condition = 'afterProbation';
        } else {
          const [frame, number] = timeCondition;
          this.timeFrameNumber = number;
          this.selectedTimeFrame = frame;
          if (this.timeFrameNumber < 0) {
            this.condition = 'beforeProbation';
          } else {
            this.condition = 'afterProbation';
          }
        }
      }
      this.setIndexStepByEntity();
    }
  },
  methods: {
    validation,
    handleError,
    handleClose() {
      this.$emit('close');
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
      this.$emit('input', name, value, target);
    },
    handleTimeChange(name, value, target) {
      this[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    /*eslint-disable*/
    /*
    Костыль до перехода на api v2, т.к. ответ ошибки может содержать разную структуру
    при разных условиях валидации
    {"error":{"code":"BAD_REQUEST","message":"Invalid request payload","data":[{"key":"name","value":"Уровень с таким названием уже существует"}]}}
    и другой вариант
    {"error":{"code":"BAD_REQUEST","message":"Нельзя создать уровень: достижение с таким названием уже существует в этой локации"}}
     */
    /* eslint-enable */
    catchAndIdentifyError(error) {
      if (!error.response.data.error?.data) {
        return this.handleUniqueError(error);
      } return this.handleError(error);
    },
    handleUniqueError(error) {
      this.uniqueError.status = true;
      this.uniqueError.message = error.response.data.error.message;
    },
    handleSave() {
      this.saving = true;
      const maleActiveUrl = cutToComma(this.entity.icon_male_url);
      const maleInactiveUrl = cutToComma(this.entity.icon_male_inactive_url);
      const femaleActiveUrl = cutToComma(this.entity.icon_female_url);
      const femaleInactiveUrl = cutToComma(this.entity.icon_female_inactive_url);
      const {
        // eslint-disable-next-line camelcase
        id, name, open_percent, location_id, for_tutor, condition,
      } = this.entity;

      const data = {
        id,
        name,
        for_tutor,
        open_percent,
        location_id,
        condition,
        icon_male_base64: maleActiveUrl,
        icon_male_inactive_base64: maleInactiveUrl,
        icon_female_base64: femaleActiveUrl,
        icon_female_inactive_base64: femaleInactiveUrl,
      };
      data.location_id = this.currentLocation;

      data.updateFields = [
        'name',
        'for_tutor',
        'open_percent',
      ];

      if (this.entity.icon_male_url.substring(0, 5) !== 'https') {
        data.updateFields.push('icon_male_base64');
      }

      if (this.entity.icon_male_inactive_url.substring(0, 5) !== 'https') {
        data.updateFields.push('icon_male_inactive_base64');
      }

      if (this.entity.icon_female_url.substring(0, 5) !== 'https') {
        data.updateFields.push('icon_female_base64');
      }

      if (this.entity.icon_female_inactive_url.substring(0, 5) !== 'https') {
        data.updateFields.push('icon_female_inactive_base64');
      }

      if (this.condition === '') {
        data.condition = {
          probation: null,
          start: null,
        };
        data.updateFields.push('opening_condition');
      } else if (this.condition === 'beforeStart') {
        data.condition = {
          start: {
            [this.selectedTimeFrame]: -Math.abs(Number.parseFloat(this.timeFrameNumber)),
          },
        };
        data.updateFields.push('opening_condition');
      } else if (this.condition === 'afterStart') {
        data.condition = {
          start: {
            [this.selectedTimeFrame]: Number.parseFloat(this.timeFrameNumber),
          },
        };
        data.updateFields.push('opening_condition');
      } else if (this.condition === 'beforeProbation') {
        data.condition = {
          probation: {
            [this.selectedTimeFrame]: -Math.abs(Number.parseFloat(this.timeFrameNumber)),
          },
        };
        data.updateFields.push('opening_condition');
      } else if (this.condition === 'afterProbation') {
        data.condition = {
          probation: {
            [this.selectedTimeFrame]: Number.parseFloat(this.timeFrameNumber),
          },
        };
        data.updateFields.push('opening_condition');
      }
      snamiApi.saveLevel({ edit: this.edit, data })
        .then(() => {
          this.$emit('save');
        })
        .catch((e) => this.catchAndIdentifyError(e))
        .finally(() => {
          this.saving = false;
        });
    },
    setIndexStepByEntity() {
      const indexStep = this.levelSteps.findIndex((v) => v === this.entity.open_percent);
      if (indexStep !== -1) {
        this.indexStep = indexStep;
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
