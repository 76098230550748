<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow no-gutters>
      <VCol>
        <h2 class="tt-text-subtitle mb-2">
          Копирование уровня
        </h2>
        <span class="tt-text-body-2">
          Выберите локацию, куда будет скопирован уровень. Он будет размещен последним в
          списке уровней выбранной локации.
        </span>
      </VCol>
    </VRow>
    <VRow class="mt-0">
      <VCol class="pt-4">
        <TTSelect
          large
          label="Локация"
          class="tt-select-attach"
          attach
          :menu-props="{ auto : true }"
          placeholder="Выберите локацию"
          :value="activeLocation"
          :items="locations"
          item-text="name"
          item-value="id"
          data-test="copy-level-form-select-locations"
          :error-messages="globalErrorMessage"
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
        <span
          v-if="globalErrorMessage"
          class="tt-text-body-2 tt-light-red--text mt-2"
        >
          Для копирования необходимо выбрать локацию.
        </span>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="d-flex justify-end pt-1 pb-3">
        <TTBtn
          large
          depressed
          :disabled="saving"
          class="mr-6"
          color="tt-secondary"
          data-test="copy-level-form-button-cancel"
          @click.stop="() => {
            handleClose();
          }"
        >
          Отменить
        </TTBtn>
        <TTBtn
          large
          depressed
          :disabled="saving"
          :loading="saving"
          data-test="copy-level-form-button-save"
          @click.stop="copy"
        >
          Скопировать
        </TTBtn>
      </VCol>
    </VRow>
    <TTBtn
      data-test="copy-level-form-close-button"
      width="20"
      height="20"
      min-width="20"
      absolute
      top
      right
      depressed
      color="transparent"
      @click.stop="() => {
        handleClose();
      }"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </VContainer>
</template>

<script>
import * as snamiApi from '@/services/backend/snami';
import { handleError } from '@/services';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'CopyLevelForm',
  components: { TextTemplate },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      location: this.locations,
      activeLocation: '',
      globalErrorMessage: '',
    };
  },
  computed: {},
  methods: {
    handleError,
    handleLocation(val) {
      this.activeLocation = val;
      this.globalErrorMessage = '';
    },
    async copy() {
      const data = {
        location_id: this.activeLocation,
        level_id: this.id,
      };
      if (data) {
        this.saving = true;
        snamiApi.copyLevel(data)
          .then(() => {
            this.$emit('copy');
          })
          .catch((e) => this.handleError(e))
          .finally(() => { this.saving = false; });
      }
    },
    handleClose(e) {
      this.activeLocation = null;
      this.$emit(e || 'close');
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
