<template>
  <TTDataTable
    ref="sortableTable"
    hide-default-footer
    :items="level"
    extra-tall
    :headers="levelHeaders"
    class="clickable"
    :items-per-page="level.length"
  >
    <template #item="{item, index}">
      <tr
        :key="itemKey(item)"
        class="cursor--pointer"
        :class="{'sortableRow' : !hasChat || index > 0}"
        @click="() => handleLevelClick(item.id)"
      >
        <td class="sortHandle">
          <div v-if="!hasChat || index > 0">
            <VIcon>fal fa-grip-lines</VIcon>
          </div>
        </td>
        <td>
          <div
            v-if="!hasChat || index > 0"
            data-test="number"
          >
            {{ hasChat ? index : index + 1 }}
          </div>
        </td>
        <td>
          <div
            data-test="name"
            style="word-break: break-word;"
          >
            {{ item.name }}
          </div>
        </td>
        <td>
          <div
            v-if="!hasChat || index > 0"
            class="d-flex"
          >
            <div
              v-if="item.icon_male_url"
              class="offset-icon"
            >
              <SAvatar
                url-key="icon_male_url"
                :item="item"
                :size="45"
              />
            </div>
            <div v-if="item.icon_male_inactive_url">
              <SAvatar
                url-key="icon_male_inactive_url"
                :item="item"
                :size="45"
              />
            </div>
          </div>
        </td>
        <td>
          <div
            v-if="!hasChat || index > 0"
            class="d-flex"
          >
            <div
              v-if="item.icon_female_url"
              class="offset-icon"
            >
              <SAvatar
                url-key="icon_female_url"
                :item="item"
                :size="45"
              />
            </div>
            <div v-if="item.icon_female_inactive_url">
              <SAvatar
                url-key="icon_female_inactive_url"
                :item="item"
                :size="45"
              />
            </div>
          </div>
        </td>
        <td
          data-test="open-percent"
        >
          {{ !hasChat || index > 0 ? item.open_percent : '' }}
        </td>
        <td
          data-test="bonus"
          class="d-flex align-center justify-space-between"
        >
          <div class="text-center mr-10">
            {{ !hasChat || index > 0 ? item.bonus : '' }}
          </div>
          <VMenu
            offset-y
            content-class="v-menu-shadow"
            left
            min-width="250px"
            nudge-right="16px"
          >
            <template #activator="{ on }">
              <TTBtn
                class="table-menu-button"
                fab
                small
                depressed
                color="transparent tt-ghost--text"
                :ripple="false"
                data-test="button-menu"
                v-on="on"
              >
                <VIcon size="19">
                  fal fa-ellipsis-h
                </VIcon>
              </TTBtn>
            </template>
            <VCard class="v-menu-card">
              <VList dense>
                <VListItem
                  class="custom-menu-item"
                  :data-test="`button-copy-${item.id}`"
                  @click="$emit('toggleLevelCopyModal', item.id)"
                >
                  <VListItemIcon>
                    <VIcon
                      size="19"
                      color="tt-black"
                    >
                      fal fa-copy
                    </VIcon>
                  </VListItemIcon>
                  <VListItemContent>Скопировать</VListItemContent>
                </VListItem>
                <VListItem
                  v-if="!hasChat || index > 0"
                  class="custom-menu-item"
                  :data-test="`button-edit-${item.id}`"
                  @click="$emit('toggleEdit', item)"
                >
                  <VListItemIcon>
                    <VIcon
                      size="19"
                      color="tt-black"
                    >
                      fal fa-edit
                    </VIcon>
                  </VListItemIcon>
                  <VListItemContent>Редактировать</VListItemContent>
                </VListItem>
                <VListItem
                  v-if="!hasChat || index > 0"
                  class="custom-menu-item"
                  :data-test="`button-delete-${item.id}`"
                  @click="$emit('openDelete', item)"
                >
                  <VListItemIcon>
                    <VIcon
                      size="19"
                      color="error"
                    >
                      fal fa-trash-alt
                    </VIcon>
                  </VListItemIcon>
                  <VListItemContent class="error--text">
                    Удалить
                  </VListItemContent>
                </VListItem>
              </VList>
            </VCard>
          </VMenu>
        </td>
      </tr>
    </template>
  </TTDataTable>
</template>

<script>
import Sortable from 'sortablejs';
import SAvatar from '@/components/SAvatar.vue';
import { R_TASK_LEVEL } from '@/plugins/vue-router';

export default {
  name: 'LevelList',
  components: {
    SAvatar,
  },
  props: {
    level: {
      type: Array,
      required: true,
    },
    hasChat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      levelHeaders: [
        {
          text: '',
          value: 'drag',
          sortable: false,
          width: 60,
        },
        {
          text: '№',
          value: 'n',
          sortable: false,
          width: 50,
        },
        {
          text: 'Название',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Муж.',
          value: 'maleIcons',
          sortable: false,
          width: 94,
        },
        {
          text: 'Жен.',
          value: 'femaleIcons',
          sortable: false,
          width: 94,
        },
        {
          text: '% открытия',
          value: 'open_percent',
          sortable: false,
          width: 120,
        },
        {
          text: 'Бонусы',
          value: 'bonus',
          sortable: false,
          width: 165,
        },
      ],
      // Sortable
      itemKeys: new WeakMap(),
      currentItemKey: 0,
    };
  },
  mounted() {
    Sortable.create(
      this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
      {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder,
      },
    );
  },
  methods: {
    handleLevelClick(id) {
      this.$router.push({ name: R_TASK_LEVEL, params: { id } });
    },
    dragReorder({ oldIndex, newIndex }) {
      const level = [...this.level];
      const movedItem = level.splice(oldIndex, 1)[0];
      level.splice(newIndex, 0, movedItem);
      this.$emit('dragReorder', level);
    },
    itemKey(item) {
      if (!this.itemKeys.has(item)) {
        this.currentItemKey += 1;
        this.itemKeys.set(item, this.currentItemKey);
      }
      return this.itemKeys.get(item);
    },
  },
};
</script>

<style scoped>
  .sortHandle {
    cursor: move;
  }
</style>
