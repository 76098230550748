var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('VRow',{attrs:{"no-gutters":""}},[_c('VCol',[_c('h2',{staticClass:"tt-text-subtitle mb-2"},[_vm._v(" Копирование уровня ")]),_c('span',{staticClass:"tt-text-body-2"},[_vm._v(" Выберите локацию, куда будет скопирован уровень. Он будет размещен последним в списке уровней выбранной локации. ")])])],1),_c('VRow',{staticClass:"mt-0"},[_c('VCol',{staticClass:"pt-4"},[_c('TTSelect',{staticClass:"tt-select-attach",attrs:{"large":"","label":"Локация","attach":"","menu-props":{ auto : true },"placeholder":"Выберите локацию","value":_vm.activeLocation,"items":_vm.locations,"item-text":"name","item-value":"id","data-test":"copy-level-form-select-locations","error-messages":_vm.globalErrorMessage},on:{"change":_vm.handleLocation},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('TextTemplate',{staticClass:"v-select__selection v-select__selection--comma",attrs:{"disabled":disabled,"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextTemplate',{attrs:{"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}}])}),(_vm.globalErrorMessage)?_c('span',{staticClass:"tt-text-body-2 tt-light-red--text mt-2"},[_vm._v(" Для копирования необходимо выбрать локацию. ")]):_vm._e()],1)],1),_c('VRow',[_c('VCol',{staticClass:"d-flex justify-end pt-1 pb-3"},[_c('TTBtn',{staticClass:"mr-6",attrs:{"large":"","depressed":"","disabled":_vm.saving,"color":"tt-secondary","data-test":"copy-level-form-button-cancel"},on:{"click":function($event){$event.stopPropagation();return (function () {
          _vm.handleClose();
        })($event)}}},[_vm._v(" Отменить ")]),_c('TTBtn',{attrs:{"large":"","depressed":"","disabled":_vm.saving,"loading":_vm.saving,"data-test":"copy-level-form-button-save"},on:{"click":function($event){$event.stopPropagation();return _vm.copy($event)}}},[_vm._v(" Скопировать ")])],1)],1),_c('TTBtn',{attrs:{"data-test":"copy-level-form-close-button","width":"20","height":"20","min-width":"20","absolute":"","top":"","right":"","depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return (function () {
      _vm.handleClose();
    })($event)}}},[_c('VIcon',{attrs:{"color":"tt-black","size":"19"}},[_vm._v(" fal fa-times ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }