<template>
  <VAvatar
    :size="size"
  >
    <VImg
      :src="hasAva ? hasAva: require('@/assets/images/nouser.png') "
    />
  </vavatar>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'SAvatar',
  props: {
    item: {
      type: Object,
      required: true,
    },
    urlKey: {
      type: String,
      default: 'image',
    },
    size: {
      type: Number,
      default: 63,
    },
  },
  computed: {
    hasAva() {
      return get(this.item, this.urlKey);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
