<template>
  <VContainer
    fluid
  >
    <DialogWrapper
      v-model="showSearchBar"
      width="100%"
      transparent
      content-class="ma-0 elevation-0 align-self-start mt-3 search-dialog"
    >
      <div
        class="search-dialog__content"
      >
        <VContainer class="pl-4">
          <VRow>
            <VCol
              cols="12"
              md="10"
              :offset="$vuetify.breakpoint.mdAndDown? 0 :1"
            >
              <VContainer
                fluid
              >
                <VRow>
                  <VCol cols="12">
                    <SearchBar
                      v-if="showSearchBar"
                      to-search-page
                      :should-validate="false"
                    />
                  </VCol>
                </VRow>
              </VContainer>
            </VCol>
          </VRow>
        </VContainer>
      </div>
    </DialogWrapper>
    <DialogWrapper
      v-model="showLevelForm"
      fullscreen
      @click:outside="closeModal('showLevelForm')"
    >
      <LevelForm
        v-if="showLevelForm"
        :current-location="currentLocationId"
        :edit="edit"
        :edit-entity="activeEntity"
        @close="closeModal('showLevelForm')"
        @save="handleSave"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="dialogLevelCopy"
      width="536"
      content-class="overflow-visible"
      scrollable
      @click:outside="closeModal"
    >
      <CopyLevelForm
        v-if="dialogLevelCopy"
        :id="actionCopy"
        :locations="locations"
        @close="closeModal"
        @copy="handleCopy"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="cancelDeleteForm"
      >
        <h3 class="delete-form__title">
          Удалить уровень?
        </h3>
        <p class="mb-0">
          После удаления уровень будет недоступен для восстановления и прохождения в приложении.
        </p>
      </DeleteForm>
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteInfoDialog"
      width="396"
      @click:outside="closeDeleteForm"
    >
      <VRow>
        <VCol
          cols="12"
          class="pt-0 pb-2"
        >
          <h2 class="text--black">
            Для удаления уровня необходимо убрать упоминания
          </h2>
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="deleteInfo.has_connection"
          cols="12"
          class="py-0"
        >
          Упоминание уровня в событиях ({{ deleteInfo.connection.length }}):
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <VDivider />
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="deleteInfo.connection"
          cosl="12"
          class="py-0"
        >
          <VList
            class="pa-0 overflow-auto"
            max-height="350px"
          >
            <!-- Хз куда это приведет... -->
            <VListItem
              v-for="item in deleteInfo.connection"
              :key="item.id"
              :to="{
                name : Names.R_EVENTS, hash : `#tab-${item.type}`,
                query : {location : currentLocationId, itemId : item.id}
              }"
              class="pa-3"
            >
              <VListItemContent class="ma-0 pa-0">
                <VListItemTitle>
                  {{ item.name }}
                </VListItemTitle>
                <VListItemSubtitle class="tt-light-mono-46--text">
                  {{ eventType[item.type] }}
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemIcon>
                <VIcon
                  size="19"
                  color="tt-black"
                >
                  fal fa-arrow-to-right
                </VIcon>
              </VListItemIcon>
            </VListItem>
          </VList>
        </VCol>
        <TTBtn
          absolute
          top
          right
          width="20"
          height="20"
          min-width="20"
          class="cursor--pointer pa-0 delete-form__cancel-icon"
          depressed
          color="transparent"
          @click="closeDeleteForm"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
      </VRow>
    </DialogWrapper>
    <VRow>
      <VCol cols="6">
        <h1 class="tt-text-headline-1">
          Уровни
        </h1>
      </VCol>
    </VRow>
    <VRow class="justify-space-between align-center mb-3">
      <VCol
        cols="12"
        class="py-0"
      >
        <TTSelect
          ref="locationDropDown"
          placeholder="Выбор локации"
          :value="currentLocationId"
          :items="locations"
          item-text="name"
          item-value="id"
          return-object
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          data-test="select-locations"
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
    </VRow>
    <VRow v-show="currentLocationId">
      <VCol>
        <h3 class="mb-4 tt-text-subtitle">
          Трек адаптации
        </h3>
        <LevelList
          :level="level"
          has-chat
          data-test="table-level"
          @toggleLevelCopyModal="toggleLevelCopyModal($event)"
          @toggleEdit="toggleEdit('showLevelForm', $event)"
          @openDelete="openDelete($event)"
          @dragReorder="dragReorder('level', $event)"
        />
      </VCol>
    </VRow>
    <VRow v-show="!currentLocationId">
      <VCol cols="12">
        <h1 class="mb-3 tt-text-headline-1">
          Не задана локация
        </h1>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
    <VRow v-show="currentLocationId && tutorLevel.length">
      <VCol cols="12">
        <VDivider />
      </VCol>

      <VCol>
        <h3 class="mb-4 tt-text-subtitle">
          Трек наставничества
        </h3>
        <LevelList
          :level="tutorLevel"
          data-test="table-tutor-level"
          @toggleLevelCopyModal="toggleLevelCopyModal($event)"
          @toggleEdit="toggleEdit('showLevelForm', $event)"
          @openDelete="openDelete($event)"
          @dragReorder="dragReorder('tutorLevel', $event)"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import * as snamiApi from '@/services/backend/snami';
import { pageLocationToken } from '@/services';
import CopyLevelForm from '@/components/forms/CopyLevelForm.vue';
import LevelForm from '@/components/forms/LevelForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import LevelList from '@/components/level/LevelList.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import SearchBar from '@/components/shared/SearchBar.vue';
import { R_CONTENT_SEARCH } from '@/plugins/vue-router';
import TextTemplate from '@/components/shared/TextTemplate.vue';
import { eventType } from '@/constants';

export default {
  name: 'Level',
  components: {
    TextTemplate,
    SearchBar,
    DialogWrapper,
    DeleteForm,
    LevelList,
    CopyLevelForm,
    LevelForm,
  },
  inject: ['Names'],
  data() {
    return {
      eventType,
      showLevelForm: false,
      edit: false,
      activeEntity: {},
      level: [],
      tutorLevel: [],
      locations: [],
      currentLocationId: 0,
      showSearchBar: false,
      deleteDialog: false,
      toDeleteId: 0,
      deleteInfo: {},
      deleteInfoDialog: false,
      dialogLevelCopy: false,
      showCopy: false,
      actionCopy: 0,
      showModal: false,
      searchText: '',
    };
  },
  created() {
    this.$repositories.location.list({})
      .then((r) => {
        const { data } = r.data;
        this.locations = data;
        // делаем массив id локаций
        const locationIds = this.locations.map((item) => item.id);
        let loc;
        // проверяем, правильные ли данные нам пришли: сравниваем с locationIds и есть ли локация с таким id
        if (this.$route.params.id !== undefined
          && locationIds.indexOf(parseInt(this.$route.params.id, 10)) !== -1) {
          // в loc записывает id локации из урла
          loc = this.$route.params.id;
          pageLocationToken.set(loc);
        } else {
          // если пришел не верный id те локации с таким id нет, то достаем из локалстораджа id
          loc = pageLocationToken.get();
        }

        if (loc) {
          this.currentLocationId = parseInt(loc, 10);
          this.getData();
        } else {
          this.$refs.locationDropDown.$children[0].isMenuActive = true;
        }
      })
      .catch((e) => console.warn(e));
  },
  mounted() {
    this.$root.$on('showLevelForm', this.createLevel);
    this.$root.$on('onClickShowSearchBar', this.toggleSearchBar);
  },
  beforeDestroy() {
    this.$root.$off('showLevelForm', this.createLevel);
    this.$root.$on('onClickShowSearchBar', this.toggleSearchBar);
  },
  methods: {
    handleChange(value) {
      this.$router.push({ name: R_CONTENT_SEARCH, query: { search: value } });
    },
    toggleSearchBar() {
      this.showSearchBar = !this.showSearchBar;
    },
    createLevel() {
      this.openModal('showLevelForm');
    },
    cancelDeleteForm() {
      this.toDeleteId = 0;
      this.deleteDialog = false;
    },
    toggleLevelCopyModal(id) {
      this.actionCopy = id;
      this.dialogLevelCopy = !this.dialogLevelCopy;
    },
    closeModal() {
      this.actionCopy = 0;
      this.dialogLevelCopy = false;
      this.showLevelForm = false;
      this.activeEntity = {};
      this.edit = false;
    },
    toggleEdit(name, item) {
      this.edit = true;
      this.activeEntity = item;
      this[name] = true;
    },
    handleSave() {
      this.getData();
      this.closeModal();
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    handleCopy() {
      this.closeModal();
      this.getData();
    },
    openDelete(item) {
      snamiApi.getContentConnections({
        entity: 'LEVEL',
        id: item.id,
      })
        .then(({ data }) => {
          if (data.data.has_connection) {
            this.deleteInfo = data.data;
            this.deleteInfoDialog = true;
          } else {
            this.deleteDialog = true;
            this.toDeleteId = item.id;
          }
        });
    },
    handleDelete() {
      snamiApi.deleteLevel(this.toDeleteId)
        .then(() => {
          this.deleteDialog = false;
          this.toDeleteId = 0;
          this.getData();
        })
        .catch((e) => console.warn(e));
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.getData();
    },
    closeDeleteForm() {
      this.deletableEventName = '';
      this.deleteInfoDialog = false;
    },
    getData() {
      snamiApi.getLevel({ filter: { location_id: this.currentLocationId } })
        .then((r) => {
          const { data } = r.data;
          this.level = data.filter((item) => !item.for_tutor);
          this.tutorLevel = data.filter((item) => item.for_tutor);
        })
        .catch((e) => console.warn(e));
    },
    dragReorder(name, levelData) {
      this[name] = levelData;

      const ids = this.level.map((item) => item.id);
      const tutorIds = this.tutorLevel.map((item) => item.id);
      const data = {
        location_id: this.currentLocationId,
        level_ids: [...ids.slice(1), ...tutorIds],
      };
      snamiApi.levelReorder(data);
    },
  },
};
</script>
<style lang="scss">
.search-dialog{
  overflow-y: inherit;
 &__content {
    padding-left: 81px;
  }
}

</style>
